import { isEmpty, map } from "lodash";
import apiHandler from "../../services/apiHandler";
import { configPath } from "../../constants/constant";
import Swal from "sweetalert2";
import Spinner from "../loader/Loader";
import { useState } from "react";


const CabListPanel = ({ list }) => {

    const [isLoading, setIsLoading] = useState(false);

    const bookTrip = async (item) => {
        const { value: formValues } = await Swal.fire({
            title: "Contact Details",
            html: `
              <input id="mobile_number" type="text" class="swal2-input" placeholder="Enter Mobile Number"><br/>
              <input id="email_id" type="email" pattern=".+@example\.com" class="swal2-input" placeholder="Enter Email">
            `,
            focusConfirm: false,
            confirmButtonText: 'Confirm',
            preConfirm: () => {
                // Validate input
                if (document.getElementById('mobile_number').value == '') {
                    Swal.showValidationMessage("Please enter your mobile number"); // Show error when validation fails.
                } else {
                    Swal.resetValidationMessage(); // Reset the validation message.
                    return [
                        document.getElementById("mobile_number").value,
                        document.getElementById("email_id").value
                    ];
                }
            }
        });

        if (!isEmpty(formValues)) {
            item['mobileNo'] = formValues[0];
            item['email'] = formValues[1];

            const resp = await apiHandler('post', `${configPath.api_baseUrl}book`, item);
            setIsLoading(true);
            if (!isEmpty(resp) && !isEmpty(resp.data)) {
                const { data } = resp;
                setIsLoading(false);
                Swal.fire({
                    title: `<strong>Thank You!</strong> <br/>
                Booking Id: ${data.bookingId} `,
                    icon: "success",
                    html: `<b>Your booking is confirmed</b>,
                  We will contact you shortly.<br/>
                  <b>आपकी बुकिंग की पुष्टि हो गई है,</b>
                  हम शीघ्र ही आपसे संपर्क करेंगे। <br/>
                         OR <br/>
                <b>You can directly call<b> <br/>
                <a href="tel:9051290512">Call <bold>${configPath.mobile_number}<bold></a>
                <a href="tel:9051290512">Call <bold>${configPath.alt_number}<bold></a>`,
                    showCloseButton: true,
                    showCancelButton: false,
                    focusConfirm: true,
                    confirmButtonText: `<i class="fa fa-thumbs-up"></i> Great!`,
                    confirmButtonAriaLabel: "Thumbs up, great!",
                });
            } else {
                Swal.fire({
                    title: `<strong>Opps!</strong>`,
                    icon: "warning",
                    html: `<b>Something went wrong!</b>,
                  Please try later.<br/>
                         OR <br/>
                <b>You can directly call<b> <br/>
                <a href="tel:9051290512">Call <bold>${configPath.mobile_number}<bold></a>
                <a href="tel:9051290512">Call <bold>${configPath.alt_number}<bold></a>`,
                    showCloseButton: true,
                    showCancelButton: false,
                    focusConfirm: true,
                    confirmButtonText: `<i class="fa fa-thumbs-up"></i> Ok!`,
                    confirmButtonAriaLabel: "Ok!",
                });
            }
        }
    };

    return (
        <div className="Table">
            <div className="Title">
                <p>Cab Details</p>
            </div>
            <div className="Heading">
                <div className="Cell">
                    <p>Cab Type</p>
                </div>
                <div className="Cell">
                    <p>Estimated Fare</p>
                </div>
                <div className="Cell">
                    <p>Estimated Distance (Km)</p>
                </div>
                <div className="Cell">
                    <p>Click To Book</p>
                </div>
            </div>
            {
                map(list, item => {
                    return (
                        <div className="Row" key={item.totalFare}>
                            <div className="Cell">
                                <p className="flex d-inline">
                                    <img style={{ maxWidth: '50px' }} src="images/mini-cooper.png" />
                                    <strong>{item.breakup.type}</strong>
                                </p>
                            </div>
                            <div className="Cell">
                                <p>Rs. {item.totalFare.toFixed(2)}</p>
                            </div>
                            <div className="Cell">
                                <p>{item.totalKilometer} (Km)</p>
                            </div>
                            <div className="Cell">
                                <button type="button" className="btn btn-primary" onClick={evt => bookTrip(item)}>Book</button>
                            </div>
                        </div>
                    )
                })
            }
            {isLoading && < Spinner />}
        </div>
    )
}

export default CabListPanel;