import logo from './logo.svg';
import './App.css';
import Header from './component/Header/Header';
import Bannersec from './component/sections/banner/banner-sec';
import ChooseUs from './component/sections/choose-us/chooseUs';
import ServicesTemplate from './component/sections/services/services';
import GetstartTemplate from './component/sections/getstarts/getstarts';
import FooterComponent from './component/sections/footer-comp/FooterComponent';

function App() {
  return (
    <div className="App container-flud">
      <Header />
      <Bannersec />
      <ChooseUs />
      <ServicesTemplate />
      <GetstartTemplate />
      <FooterComponent />
    </div>
  );
}

export default App;
