import axios from "axios";

const config = {
    // `method` is the request method to be used when making the request
    method: 'get', // default
    // `baseURL` will be prepended to `url` unless `url` is absolute.
    // It can be convenient to set `baseURL` for an instance of axios to pass relative URLs
    // to methods of that instance.
    baseURL: '',
    //enable cross mode
    mode: 'cors', 
    credentials: false,

    // `headers` are custom headers to be sent
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=utf-8',
    },

    withCredentials: false, // default

};

//create axios object by using config props
const httpClient = axios.create(config);

//request interceptor 
const reqInterceptor = config => config;
//response interceptor 
const respInterceptor = response => response;
//error interceptor 
const errorInterceptor = error => {
    return Promise.reject(error);
};

httpClient.interceptors.request.use(reqInterceptor);
httpClient.interceptors.response.use(respInterceptor, errorInterceptor);


export default httpClient;