const GetstartTemplate = () => {
    return (
        <section className="how-start2 sec-padding">
            <div className="container">
                <div className="row">
                    <div className="col-xl-5 col-sm-9 mx-auto mb-5">
                        <div className="sec-intro text-center">
                            <h2 className="sec-title">how to <span className="highlighted">get started</span></h2>
                            <p>Reliable Roamers Travels helps you with online cab booking at the best prices, comparatively much lower than the local vendors. Also you can use promo codes and offers on the Reliable Roamers Travels website & app on online cab booking to get extra discounts</p>
                        </div>
                    </div>
                </div>
                <div className="row work-progress">
                    <div className="d-none d-lg-flex justify-content-between align-items-center">
                        <span>01</span>
                        <span>02</span>
                        <span>03</span>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <div className="card">
                            <h3 className="h4 text-capitalize">Find a Taxi</h3>
                            <p>Your Journey, Your Way.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <div className="card">
                            <h3 className="h4 text-capitalize">meet your driver</h3>
                            <p>Where you’re going, we’re already there.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <div className="card">
                            <h3 className="h4 text-capitalize">enjoy your trip</h3>
                            <p>Sit back and relax, we’ll take care of the rest.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default GetstartTemplate;