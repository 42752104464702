import httpClient from "./httpClient";

export const apiHandler = async (method = 'post', apiUrl, bodyParam = null, queryString = null) => {
    //param query 
    let paramData;
    if ((method === 'get' || method === 'delete') && bodyParam) {
        paramData = {
            params: bodyParam,
            forceUpdate: true
        }
    } else {
        paramData = bodyParam;
    }

    return new Promise((resolve, reject) => {
        httpClient[method](apiUrl, paramData, queryString)
            .then(res => resolve(res), err => resolve(err))
            .catch(err => reject(err));
    });

}

export default apiHandler;